<template>
    <div>
        <div class="row">
            <div class="col">

                <div class="card mg-b-20">
                    <div class="card-header">
                        <h4>
                            Filters
                        </h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <div class="col-md-3">
                                <div class="form-group">

                                    <label class="form-control-label">Reference</label>
                                    <input type="text" class="form-control" v-model="filters.reference" placeholder="Reference number...">

                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">

                                    <label class="form-control-label">Claim Type</label>
                                    <select class="form-control" v-model="filters.claim_type">
                                        <option value="">All</option>
                                        <option value="Parking">Parking</option>
                                        <option value="Drive Off">Drive Off</option>
                                        <option value="Customer Not at Fault">Customer Not at Fault</option>
                                        <option value="No Means of Payment">No Means of Payment</option>
                                        <option value="Other">Other</option>
                                    </select>

                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">

                                    <label class="form-control-label">Date Received</label>
                                    <input type="date" class="form-control" v-model="filters.date_received">

                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group">

                                    <label class="form-control-label">Do you want to export?</label>
                                    <select class="form-control" v-model="filters.export">
                                        <option :value="false">No</option>
                                        <option :value="true">Yes</option>
                                    </select>

                                </div>
                            </div>

                            <div class="col-md-3" v-if="filters.export">
                                <div class="form-group">
                                    
                                    <label class="form-control-label">Start Date</label>
                                    <input type="date" class="form-control" v-model="filters.start_date">

                                </div>
                            </div>

                            <div class="col-md-3" v-if="filters.export">
                                <div class="form-group">
                                        
                                    <label class="form-control-label">End Date</label>
                                    <input type="date" class="form-control" v-model="filters.end_date">

                                </div>
                            </div>
                        </div>
                        <div class="form-layout-footer">
                            <button class="btn btn-info" @click="getComplaints">Search</button>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h4>
                            Complaints
                            <button class="btn btn-sm btn-primary float-right" v-if="$can('complaints-create')" @click="openCreateModal">Log Complaint</button>
                        </h4>
                    </div>
                    <div class="card-body">

                        <pagination :data="pagination" :show-count="true" @search="getComplaints"></pagination>

                        <table class="table table-responsive">
                            <thead class="thead-primary">
                                <tr>
                                    <th>#</th>
                                    <th>Created By</th>
                                    <th>Reference</th>
                                    <th style="min-width: 20em;">Name</th>
                                    <th>Date Received</th>
                                    <th style="min-width: 15em;">Claim Type</th>
                                    <th>Users</th>
                                    <th style="min-width: 100em;">Reason</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="complaints.length < 1 && !loading">
                                    <td colspan="7" class="text-center">No complaints found.</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="7" class="text-center">Loading...</td>
                                </tr>
                                <tr v-for="complaint in complaints" v-if="!loading">
                                    <td>{{ complaint.id }}</td>
                                    <td>{{ complaint.created_by }}</td>
                                    <td>{{ complaint.reference }}</td>
                                    <td>{{ complaint.name }}</td>
                                    <td>{{ complaint.date_received_formatted }}</td>
                                    <td>{{ complaint.claim_type }}</td>
                                    <td>
                                        {{ complaint.users.map(user => user.email).join(', ') }}
                                    </td>
                                    <td>{{ complaint.reason }}</td>
                                    <td>
                                        <button v-if="$can('complaints-update')" class="btn btn-sm btn-primary mg-r-10" @click="openComplaint(complaint)">Edit</button>
                                        <button v-if="$can('complaints-delete')" :disabled="deleting" class="btn btn-sm btn-danger" @click="deleteComplaint(complaint)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <pagination :data="pagination" :show-count="true" @search="getComplaints"></pagination>
                        
                    </div>
                </div>

            </div>
        </div>


        <edit-modal id="editComplaintModal" title="Edit Complaint">

            <div class="form-layout form-layout-2" v-if="currentComplaint">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Name: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="currentComplaint.name" placeholder="Name of the person who has lodged the complaint">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reference: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="currentComplaint.reference" placeholder="Unique reference assigned to the claim">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Date Received: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="date" v-model="currentComplaint.date_received" placeholder="Unique reference assigned to the claim">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Claim Type: <span class="tx-danger">*</span></label>
                            <select class="form-control" v-model="currentComplaint.claim_type">
                                <option value="Parking">Parking</option>
                                <option value="Drive Off">Drive Off</option>
                                <option value="Customer Not at Fault">Customer Not at Fault</option>
                                <option value="No Means of Payment">No Means of Payment</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reason for the complaint: <span class="tx-danger">*</span></label>
                            <textarea class="form-control" type="text" v-model="currentComplaint.reason" placeholder="Please enter the reason for the complaint..." rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Users dealing with complaint</label>
                            <select class="form-control" v-model="currentComplaint._users" multiple>
                                <option v-for="user in availableUsers" :value="user.id">{{ user.email }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button :clicked="updating" :background="'btn btn-info'" :text="'Save Changes'" @click="updateComplaint" />
                    <button class="btn btn-secondary" id="closeEditComplaintModal" data-bs-dismiss="modal">Cancel</button>
                </div><!-- form-group -->
            </div>

        </edit-modal>

        <edit-modal id="createComplaintModal" title="Create Complaint">

            <div class="form-layout form-layout-2">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Name: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="complaint.name" placeholder="Name of the person who has lodged the complaint">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reference: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="text" v-model="complaint.reference" placeholder="Unique reference assigned to the claim">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Date Received: <span class="tx-danger">*</span></label>
                            <input class="form-control" type="date" v-model="complaint.date_received" placeholder="Unique reference assigned to the claim">
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Claim Type: <span class="tx-danger">*</span></label>
                            <select class="form-control" v-model="complaint.claim_type">
                                <option value="Parking">Parking</option>
                                <option value="Drive Off">Drive Off</option>
                                <option value="Customer Not at Fault">Customer Not at Fault</option>
                                <option value="No Means of Payment">No Means of Payment</option>
                            </select>
                        </div>
                    </div>
                </div><!-- row -->
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-control-label">Reason for the complaint: <span class="tx-danger">*</span></label>
                            <textarea class="form-control" type="text" v-model="complaint.reason" placeholder="Please enter the reason for the complaint..." rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-layout-footer bd pd-20 bd-t-0">
                    <submit-button :clicked="creating" :background="'btn btn-info'" :text="'Save Changes'" @click="createComplaint" />
                    <button class="btn btn-secondary" id="closeCreateComplaintModal" data-bs-dismiss="modal">Cancel</button>
                </div><!-- form-group -->
            </div>

        </edit-modal>

    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import pagination from '@/components/Pagination.vue';
import {DateTime} from 'luxon';
import EditModal from '@/components/EditModal.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import { ExportToCsv } from 'export-to-csv';

export default {
    name: 'Complaints',
    components: {
        pagination,
        EditModal,
        SubmitButton
    },
    data(){
        return {
            complaints: [],
            pagination: {
                currPage: 1,
                perPage: 25,
                total: 0,
                from: 1,
                to: 0,
                lastPage: 0,
                totalResults: 0,
                maxPage: 0
            },
            filters: {
                reference: null,
                claim_type: null,
                date_received: null,
                start_date: null,
                end_date: null,
                export: false
            },
            loading: false,
            currentComplaint: null,
            updating: false,
            complaint: {
                name: null,
                reference: null,
                date_received: null,
                claim_type: null,
                reason: null
            },
            creating: false,
            deleting: false,
            availableUsers: []
        }
    },
    mounted(){
        this.getComplaints();
        this.loadClaimProcessors();
    },
    methods: {
        loadClaimProcessors(){
            axios.get(`https://api.varsanpr.com/api/users/processors`, {
                headers: authHeader()
            })
            .then(response => {
                this.availableUsers = response.data.users;
            })
            .catch(error => {
                this.$error("Failed loading processors!", error);
            });
        },
        getComplaints(page = 1){
            if(this.loading) return;
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/complaints?page=${page}&count=${this.pagination.perPage}`, {
                headers: authHeader(),
                params: this.filters
            })
            .then(res => {
                this.complaints = res.data.complaints;
                this.pagination.currPage = res.data.pagination.page;
                this.pagination.totalResults = res.data.pagination.total;
                this.pagination.from = this.pagination.currPage * this.pagination.perPage - this.pagination.perPage + 1;
                this.pagination.to = this.pagination.currPage * this.pagination.perPage;
                this.pagination.lastPage = res.data.pagination.pages;
                this.pagination.maxPage = res.data.pagination.pages;


                this.complaints.forEach((complaint) => {
                    complaint.date_received_formatted = DateTime.fromSeconds(complaint.date_received).toFormat('dd/MM/yyyy');
                    complaint.date_received = DateTime.fromSeconds(complaint.date_received).toFormat('yyyy-MM-dd');
                });

                if(this.filters.export) {

                    const options = {
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalSeparator: '.',
                        showLabels: true,
                        showTitle: true,
                        title: 'Complaints Report ' + this.filters.start_date + ' - ' + this.filters.end_date,
                        useTextFile: false,
                        useBom: true,
                        useKeysAsHeaders: true,
                        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                    };

                    const csvExporter = new ExportToCsv(options);

                    csvExporter.generateCsv(this.complaints);
                    
                }

            })
            .catch(err => {
                this.$error(err?.response?.data?.message || err?.message || err, err);
            })
            .finally(() => {
                this.loading = false;
            })
        },
        openComplaint(complaint){
            this.currentComplaint = complaint;
            this.currentComplaint._users = complaint.users.map(user => user.user_id);
            $('#editComplaintModal').modal('show');
        },  
        updateComplaint(){
            if(this.updating) return;
            this.updating = true;
            axios.patch(`https://api.varsanpr.com/api/complaints`, {
                id: this.currentComplaint.id,
                name: this.currentComplaint.name,
                reference: this.currentComplaint.reference,
                date_received: this.currentComplaint.date_received,
                claim_type: this.currentComplaint.claim_type,
                reason: this.currentComplaint.reason
            }, {
                headers: authHeader()
            })
            .then(async res => {

                // Check if the users has changed
                let users = this.currentComplaint._users;
                let currentUsers = this.currentComplaint.users.map(user => user.user_id);

                let toAdd = users.filter(user => !currentUsers.includes(user));
                let toRemove = currentUsers.filter(user => !users.includes(user));

                if(toAdd.length > 0){
                    for await (let user of toAdd){
                        await this.addUserToComplaint(user);
                    }
                }
                if(toRemove.length > 0){
                    for await (let user of toRemove){
                        await this.removeUserFromComplaint(user);
                    }
                }

                this.$success('Complaint updated successfully.');
                $('#closeEditComplaintModal').click();
                this.getComplaints();
                this.currentComplaint = null;
            })
            .catch(err => {
                this.$error(err?.response?.data?.message || err?.message || err, err);
            })
            .finally(() => {
                this.updating = false;
            });
        },
        async addUserToComplaint(user){
            return await axios.post(`https://api.varsanpr.com/api/complaints/${this.currentComplaint.id}/users`, {
                user_id: user
            }, {
                headers: authHeader()
            });
        },
        async removeUserFromComplaint(user){
            return await axios.delete(`https://api.varsanpr.com/api/complaints/${this.currentComplaint.id}/users`, {
                headers: authHeader(),
                data: {
                    user_id: user
                }
            });
        },
        openCreateModal(){
            $('#createComplaintModal').modal('show');
        },
        createComplaint(){
            if(this.creating) return;
            this.creating = true;

            axios.post(`https://api.varsanpr.com/api/complaints`, {
                name: this.complaint.name,
                reference: this.complaint.reference,
                date_received: this.complaint.date_received,
                claim_type: this.complaint.claim_type,
                reason: this.complaint.reason
            }, {
                headers: authHeader()
            })
            .then(res => {
                this.$success('Complaint created successfully.');
                $('#closeCreateComplaintModal').click();
                this.getComplaints();
                this.complaint = {
                    name: null,
                    reference: null,
                    date_received: null,
                    claim_type: null,
                    reason: null
                }
            })
            .catch(err => {
                this.$error(err?.response?.data?.message || err?.message || err, err);
            })
            .finally(() => {
                this.creating = false;
            });
        },
        deleteComplaint(complaint){
            if(this.deleting) return;
            this.deleting = true;

            axios.delete(`https://api.varsanpr.com/api/complaints`, {
                headers: authHeader(),
                data: {
                    id: complaint.id
                }
            })
            .then(res => {
                this.$success('Complaint deleted successfully.');
                this.getComplaints();
            })
            .catch(err => {
                this.$error(err?.response?.data?.message || err?.message || err, err);
            })
            .finally(() => {
                this.deleting = false;
            });
        }
    }
}
</script>